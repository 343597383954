new WOW({offset: 70}).init();


function loadSliders() {
	$('.footer-gallery').slick({
		centerMode: true,
		centerPadding: '0',
		slidesToShow: 5,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 1
				}
			}
		]
	});
}

var sliderScript = document.getElementById("deferredSlickScript");

sliderScript.addEventListener('load', function () {
	loadSliders();
});

$(document).ready(function () {

	function fixHeights() {
		$(".Testimonial").sameHeight();
	}

	$(window).on("resize", function () {
		setTimeout(fixHeights, 500);
	});

	setTimeout(fixHeights, 500);

	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});

	$("a.has-tooltip").tooltip({
		placement: "auto"
	});

});

